import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ReactNode } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { InputProps } from ".";
import { ItemAttributeOption } from "../../../../generated/axios";
import { includeSelectFilter } from "../../../../shared/lib/selectFilterOptions";
import { sortOptionsByProperty } from "../../../../shared/lib/sortArrayByField";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import ReadonlyField from "./ReadonlyField";

const Option = Select.Option;

interface ReduxProps {
  isReadonly: boolean;
}

interface Props extends InputProps, ReduxProps, InjectedIntlProps {}

interface State {
  convertedValue?: string[];
  errorCounter: string;
  value?: string[];
}

class SelectMultiple extends React.Component<Props, State> {
  state = {
    value: undefined,
    convertedValue: undefined,
    errorCounter: "0",
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { value, options = [] } = props;
    if (state.value !== value) {
      let convertedValue = options
        .filter((opt) => value.includes(opt.value))
        .map((item) => item.label);
      if (convertedValue.length === 0) convertedValue = ["-"];
      return { value, convertedValue };
    }
    return null;
  }

  onChangeError = () => {
    this.setState(() => ({
      errorCounter: `${Date.now()}`,
    }));
  };

  onChange = (e: SelectValue): void => {
    if (this.props.onChange) {
      const formEvent: FormEvent = {
        field: this.props.fieldId ? this.props.fieldId : "",
        value: e,
        onError: this.onChangeError,
      };
      this.props.onChange(formEvent);
    }
  };

  render = (): ReactNode => {
    const { fieldId, isReadonly, options, sortDisabled, type, value } =
      this.props;
    const { errorCounter, convertedValue = [] } = this.state;

    const sortProperty = type === "number" ? "value" : "label";

    const optionList: ItemAttributeOption[] = sortDisabled
      ? options ?? []
      : sortOptionsByProperty(options ?? [], sortProperty);

    if (isReadonly) return <ReadonlyField text={convertedValue.join(", ")} />;

    const onlyOneOption = optionList.length === 1;

    const id = "configuration-form--" + fieldId;

    const prefix = "";

    return (
      <Select
        key={`${fieldId}-${prefix}_${errorCounter}`}
        id={id}
        mode="multiple"
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        onChange={this.onChange}
        optionFilterProp="label"
        filterOption={includeSelectFilter}
        data-test={id}
        dropdownClassName={id + "-dropdown"}
        dropdownMatchSelectWidth={false}
      >
        {optionList.map((option) => (
          <Option
            value={option.value}
            key={option.value}
            disabled={
              option.disabled &&
              // ALFAPS-3370 non posso avere opzioni disabilitate selezionate (inconsistent-options validation error)
              // a meno che non sia l'unica opzione della select multipla
              (onlyOneOption || !(value ?? []).includes(option.value))
            }
            data-test={id + "-" + option.value}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    );
  };
}

const mapStateToProps = (state: IStore): ReduxProps => {
  const isReadonly = hasReadPermission(state);
  return {
    isReadonly,
  };
};

const WithIntl = injectIntl<InputProps>(SelectMultiple);

export default connect(mapStateToProps)(WithIntl);
