import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { get } from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { connect } from "react-redux";
import { InputProps } from ".";
import { FormAction, ItemAttributeOption } from "../../../../generated/axios";
import { sortOptionsByProperty } from "../../../../shared/lib/sortArrayByField";
import { hasReadPermission } from "../../../../shared/store/item/selectors";
import { IStore } from "../../../../shared/store/types";
import { FormEvent } from "../../../../types/configurator";
import "./checkboxMultiple.scss";
import EnhancedOption from "./EnhancedOption";
import ReadonlyField from "./ReadonlyField";

interface OptionsProps {
  options: ItemAttributeOption[];
  optionActions: { [key: string]: FormAction[] };
  dataTestAttribute: string;
  value: Array<string | number>;
}

interface IState {
  value?: string[];
  convertedValue?: string[];
  errorCounter: string;
}

interface IStoreProps {
  isReadonly: boolean;
}

interface IProps extends IStoreProps, InputProps {}

const groupStyle = { width: "100%" };

const Options = ({
  options,
  optionActions,
  dataTestAttribute,
  value,
}: OptionsProps): ReactElement<ReactNode> => {
  const sortedOptions = sortOptionsByProperty(options ?? [], "label");
  const onlyOneOption = sortedOptions.length === 1;

  const selectedAndDisabledOptions = options
    ?.filter((o) => o.disabled)
    .filter((o) => value.includes(o.value))
    .map((o) => o.value);

  const selectedAndEnabledOptions = options
    ?.filter((o) => !o.disabled)
    .filter((o) => value.includes(o.value))
    .map((o) => o.value); 

  const inconsistent = !selectedAndEnabledOptions?.length && selectedAndDisabledOptions?.length > 1; 

  return (
    <>
      {sortedOptions.map((option, index) => {
        const actions = get(optionActions, `${option.value}`, []);

        return (
          <EnhancedOption
            key={option.label ?? index}
            option={option}
            actions={actions}
          >
            <Checkbox
              value={option.value}
              disabled={
                option.disabled &&
                // ALFAPS-3370 non posso avere opzioni disabilitate selezionate (inconsistent-options validation error)
                // a meno che non sia l'unico checkbox
                (onlyOneOption || !inconsistent)
              }
              data-test={dataTestAttribute + "-" + option.label}
            >
              {option.label}
            </Checkbox>
          </EnhancedOption>
        );
      })}
    </>
  );
};

class CheckboxMultiple extends React.Component<IProps, IState> {
  state = {
    value: undefined,
    convertedValue: undefined,
    errorCounter: "0",
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { value, options = [] } = props;
    if (state.value !== value) {
      const convertedValue = options
        .filter((opt) => value.includes(opt.value))
        .map((item) => item.label);
      return { value, convertedValue };
    }
    return null;
  }

  shouldComponentUpdate(newProps: IProps, newState: IState) {
    const { fieldId, isReadonly, optionActions, options, value } = this.props;
    return (
      newState !== this.state ||
      newProps.fieldId !== fieldId ||
      newProps.isReadonly !== isReadonly ||
      newProps.value !== value ||
      newProps.options !== options ||
      newProps.optionActions !== optionActions
    );
  }

  onChangeError = () => {
    this.setState(() => ({
      errorCounter: `${Date.now()}`,
    }));
  };

  onChange = (e: CheckboxValueType[]) => {
    if (this.props.onChange) {
      const formEvent: FormEvent = {
        field: this.props.fieldId ? this.props.fieldId : "",
        value: e,
        onError: this.onChangeError,
      };
      this.props.onChange(formEvent);
    }
  };

  render = (): ReactNode => {
    const { fieldId, isReadonly, optionActions = {}, value } = this.props;
    const { errorCounter, convertedValue = [] } = this.state;
    const options: ItemAttributeOption[] = this.props.options ?? [];

    if (isReadonly) return <ReadonlyField text={convertedValue.join(", ")} />;
    const id = "configuration-form--" + fieldId;

    return (
      <div id={id}>
        <Checkbox.Group
          key={`${fieldId}-${value.join("-")}_${errorCounter}`}
          defaultValue={value} // read from this.state.value ?
          style={groupStyle}
          prefixCls="checkbox__wrapper"
          onChange={this.onChange}
          data-test={id}
        >
          <Options
            options={options}
            optionActions={optionActions}
            dataTestAttribute={id}
            value={value}
          />
        </Checkbox.Group>
      </div>
    );
  };
}

export { CheckboxMultiple as CheckboxMultipleWithoutRedux };
export default connect<IStoreProps>((state: IStore) => ({
  isReadonly: hasReadPermission(state),
}))(CheckboxMultiple);
