import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import toFragment from 'rc-util/es/Children/mapSelf';
import MonthPanel from '../month/MonthPanel';
import YearPanel from '../year/YearPanel';
import DecadePanel from '../decade/DecadePanel';

function goMonth(direction) {
  var next = this.props.value.clone();
  next.add(direction, 'months');
  this.props.onValueChange(next);
}

function goYear(direction) {
  var next = this.props.value.clone();
  next.add(direction, 'years');
  this.props.onValueChange(next);
}

function showIf(condition, el) {
  return condition ? el : null;
}

var CalendarHeader = function (_React$Component) {
  _inherits(CalendarHeader, _React$Component);

  function CalendarHeader(props) {
    _classCallCheck(this, CalendarHeader);

    var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));

    _initialiseProps.call(_this);

    _this.nextMonth = goMonth.bind(_this, 1);
    _this.previousMonth = goMonth.bind(_this, -1);
    _this.nextYear = goYear.bind(_this, 1);
    _this.previousYear = goYear.bind(_this, -1);

    _this.state = { yearPanelReferer: null };
    return _this;
  }

  CalendarHeader.prototype.render = function render() {
    var _this2 = this;

    var props = this.props;
    var prefixCls = props.prefixCls,
        locale = props.locale,
        mode = props.mode,
        value = props.value,
        showTimePicker = props.showTimePicker,
        enableNext = props.enableNext,
        enablePrev = props.enablePrev,
        disabledMonth = props.disabledMonth,
        renderFooter = props.renderFooter;


    var panel = null;
    if (mode === 'month') {
      panel = React.createElement(MonthPanel, {
        locale: locale,
        value: value,
        rootPrefixCls: prefixCls,
        onSelect: this.onMonthSelect,
        onYearPanelShow: function onYearPanelShow() {
          return _this2.showYearPanel('month');
        },
        disabledDate: disabledMonth,
        cellRender: props.monthCellRender,
        contentRender: props.monthCellContentRender,
        renderFooter: renderFooter,
        changeYear: this.changeYear
      });
    }
    if (mode === 'year') {
      panel = React.createElement(YearPanel, {
        locale: locale,
        defaultValue: value,
        rootPrefixCls: prefixCls,
        onSelect: this.onYearSelect,
        onDecadePanelShow: this.showDecadePanel,
        renderFooter: renderFooter
      });
    }
    if (mode === 'decade') {
      panel = React.createElement(DecadePanel, {
        locale: locale,
        defaultValue: value,
        rootPrefixCls: prefixCls,
        onSelect: this.onDecadeSelect,
        renderFooter: renderFooter
      });
    }

    return React.createElement(
      'div',
      { className: prefixCls + '-header' },
      React.createElement(
        'div',
        { style: { position: 'relative' } },
        showIf(enablePrev && !showTimePicker, React.createElement('a', {
          className: prefixCls + '-prev-year-btn',
          role: 'button',
          onClick: this.previousYear,
          title: locale.previousYear
        })),
        showIf(enablePrev && !showTimePicker, React.createElement('a', {
          className: prefixCls + '-prev-month-btn',
          role: 'button',
          onClick: this.previousMonth,
          title: locale.previousMonth
        })),
        this.monthYearElement(showTimePicker),
        showIf(enableNext && !showTimePicker, React.createElement('a', {
          className: prefixCls + '-next-month-btn',
          onClick: this.nextMonth,
          title: locale.nextMonth
        })),
        showIf(enableNext && !showTimePicker, React.createElement('a', {
          className: prefixCls + '-next-year-btn',
          onClick: this.nextYear,
          title: locale.nextYear
        }))
      ),
      panel
    );
  };

  return CalendarHeader;
}(React.Component);

CalendarHeader.propTypes = {
  prefixCls: PropTypes.string,
  value: PropTypes.object,
  onValueChange: PropTypes.func,
  showTimePicker: PropTypes.bool,
  onPanelChange: PropTypes.func,
  locale: PropTypes.object,
  enablePrev: PropTypes.any,
  enableNext: PropTypes.any,
  disabledMonth: PropTypes.func,
  renderFooter: PropTypes.func,
  onMonthSelect: PropTypes.func
};
CalendarHeader.defaultProps = {
  enableNext: 1,
  enablePrev: 1,
  onPanelChange: function onPanelChange() {},
  onValueChange: function onValueChange() {}
};

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this.onMonthSelect = function (value) {
    _this3.props.onPanelChange(value, 'date');
    if (_this3.props.onMonthSelect) {
      _this3.props.onMonthSelect(value);
    } else {
      _this3.props.onValueChange(value);
    }
  };

  this.onYearSelect = function (value) {
    var referer = _this3.state.yearPanelReferer;
    _this3.setState({ yearPanelReferer: null });
    _this3.props.onPanelChange(value, referer);
    _this3.props.onValueChange(value);
  };

  this.onDecadeSelect = function (value) {
    _this3.props.onPanelChange(value, 'year');
    _this3.props.onValueChange(value);
  };

  this.changeYear = function (direction) {
    if (direction > 0) {
      _this3.nextYear();
    } else {
      _this3.previousYear();
    }
  };

  this.monthYearElement = function (showTimePicker) {
    var props = _this3.props;
    var prefixCls = props.prefixCls;
    var locale = props.locale;
    var value = props.value;
    var localeData = value.localeData();
    var monthBeforeYear = locale.monthBeforeYear;
    var selectClassName = prefixCls + '-' + (monthBeforeYear ? 'my-select' : 'ym-select');
    var timeClassName = showTimePicker ? ' ' + prefixCls + '-time-status' : '';
    var year = React.createElement(
      'a',
      {
        className: prefixCls + '-year-select' + timeClassName,
        role: 'button',
        onClick: showTimePicker ? null : function () {
          return _this3.showYearPanel('date');
        },
        title: showTimePicker ? null : locale.yearSelect
      },
      value.format(locale.yearFormat)
    );
    var month = React.createElement(
      'a',
      {
        className: prefixCls + '-month-select' + timeClassName,
        role: 'button',
        onClick: showTimePicker ? null : _this3.showMonthPanel,
        title: showTimePicker ? null : locale.monthSelect
      },
      locale.monthFormat ? value.format(locale.monthFormat) : localeData.monthsShort(value)
    );
    var day = void 0;
    if (showTimePicker) {
      day = React.createElement(
        'a',
        {
          className: prefixCls + '-day-select' + timeClassName,
          role: 'button'
        },
        value.format(locale.dayFormat)
      );
    }
    var my = [];
    if (monthBeforeYear) {
      my = [month, day, year];
    } else {
      my = [year, month, day];
    }
    return React.createElement(
      'span',
      { className: selectClassName },
      toFragment(my)
    );
  };

  this.showMonthPanel = function () {
    // null means that users' interaction doesn't change value
    _this3.props.onPanelChange(null, 'month');
  };

  this.showYearPanel = function (referer) {
    _this3.setState({ yearPanelReferer: referer });
    _this3.props.onPanelChange(null, 'year');
  };

  this.showDecadePanel = function () {
    _this3.props.onPanelChange(null, 'decade');
  };
};

export default CalendarHeader;