import expr from "expression-eval";
import { FieldValidationRule } from "../../generated/axios";
import { Any } from "../../types/Any";
import {
  ByKey,
  ValidationMessage,
  ValidationParams,
  validationStatus,
} from "../../types/configurator";
import { eIntl } from "../eIntl";
import { fillParam, FillParamsError, replaceParams } from "../lib/fillParams";
import log from "../log";
import { FormFieldWithValue } from "../store/configurator/types";

type ValidationFunction = (value: Any, ...params: Any[]) => boolean;

interface RuleMessageParams {
  name?: string;
  fieldId: string;
  messageKey?: string;
}

export const isEmpty = (value: Any): boolean => {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) // For SelectMultiple value is an array
  );
};

export const validateRequired = (field: FormFieldWithValue): boolean => {
  if (!field.validation) {
    return true;
  }
  const required = Boolean(field.validation.required);
  const empty = isEmpty(field.value);
  return !(required && empty);
};

const toValidateProps = (
  status: string,
  message?: string,
  params?: ValidationParams,
  skipTranslate?: true
): ValidationMessage => ({
  status: validationStatus[status],
  message,
  params,
  skipTranslate,
});

/**
 * Returns the message id associated to rule. To be translated with react intl
 */
const ruleMessage = ({
  name,
  fieldId,
  messageKey,
}: RuleMessageParams): string => {
  if (!name) {
    log.warn(`Rule MUST have a name property`);
    return "";
  }

  // Fallback chain for validation message. See ALFAPS-2453
  const fallbackChain = [
    `validation.${name}.${fieldId}.${messageKey}`,
    `validation.${name}.${fieldId}`,
    `validation.${name}`,
  ];

  const id = fallbackChain.find((key) => key in eIntl.messages);
  const message = id ?? "";

  if (!message) {
    log.warn(`Message not defined for rule ${name}`);
  }

  return message;
};

/**
 * Returns a Record with numeric keys starting from 1 (to be used as params map when translating)
 */
const arrayToRecord = (arr: Any[]): Record<string, Any> => {
  return arr.reduce((res, el, index) => {
    const mapIndex = index + 1;
    res[mapIndex] = el;
    return res;
  }, {});
};

const toError = (
  message?: string,
  params?: ValidationParams,
  skipTranslate?: true
): ValidationMessage =>
  toValidateProps(validationStatus.error, message, params, skipTranslate);

const toSuccess = (
  message?: string,
  params?: ValidationParams
): ValidationMessage =>
  toValidateProps(validationStatus.success, message, params);

const validateRule = (
  rule: FieldValidationRule,
  validationRules: ByKey
): ValidationFunction => {
  if (!rule.name) {
    log.warn(`Rule MUST have a name property`);
    return () => true;
  }
  const fn = validationRules[rule.name];
  if (fn === undefined) {
    log.warn(`Rule ${rule.name} is not defined!`);
    return () => true;
  }
  return fn;
};

const validate =
  (validationRules: ByKey, visibility: Record<string, boolean>) =>
  (field: FormFieldWithValue, values: ByKey): ValidationMessage[] => {
    // Create new array of rules from field.validation.rules
    const rules = [...(field.validation?.rules ?? [])];

    if (!validateRequired(field)) {
      const message: string = ruleMessage({
        name: "required",
        fieldId: field.fieldId,
        // messageKey: undefined
      });
      return [toError(message, { "1": field.label })];
    }

    // requiredIfEmpty rule handling
    // It is different from other rules:
    // - it must be evaluated before isEmpty check (see some lines below)
    // - in replacing inputParameter values (they aren't numeric expression)
    // Find and remove from rules (using splice) the "requiredIfEmpty" rule
    const indexOfRequiredIfEmpty = rules.findIndex(
      (rule) => rule.name === "requiredIfEmpty"
    );
    const [rule] = rules.splice(
      indexOfRequiredIfEmpty,
      indexOfRequiredIfEmpty !== -1 ? 1 : 0
    );
    if (rule) {
      const inputParameters = rule.inputParameters ?? [];
      const params: string[] = replaceParams(inputParameters, values).map(
        (param) => expr.compile(param)({})
      );
      const isValid = validateRule(rule, validationRules)(
        field.value,
        ...params
      );
      if (!isValid) {
        const visibleFields = inputParameters
          // all expressions are assumed to be fieldId names only
          // extract fieldsId removing "${}" characters
          .map((expression) => expression.replace(/[\s{$}]/g, ""))
          .filter((fieldId) => visibility[fieldId]);
        const message: string = ruleMessage(
          // ALFAPS-3299: if only one fields is visible show standard required validation message
          visibleFields.length == 1
            ? {
                name: "required",
                fieldId: field.fieldId,
              }
            : {
                name: rule.name,
                fieldId: field.fieldId,
                messageKey: rule.messageKey,
              }
        );
        return [toError(message, { "1": field.label })];
      }
    }

    // skip validation if field is not required and empty
    if (isEmpty(field.value)) {
      return [toSuccess()];
    }

    // Test all other validation rules
    const errors: ValidationMessage[] = rules.reduce((errors, rule) => {
      try {
        const inputParameters = rule.inputParameters ?? [];

        const params: string[] = inputParameters.map((param) => {
          const p = fillParam(param, values);
          return expr.compile(p)({});
        });

        const isValid = validateRule(rule, validationRules)(
          field.value,
          ...params
        );

        if (!isValid) {
          if (rule.name === "message") {
            // Handling "message" validation rules
            const validationMessage = toError(params[0], undefined, true);
            errors.push(validationMessage);
          } else {
            const message: string = ruleMessage({
              name: rule.name,
              fieldId: field.fieldId,
              messageKey: rule.messageKey,
            });

            const validationMessage = toError(message, arrayToRecord(params));
            errors.push(validationMessage);
          }
        }
      } catch (e) {
        // Validation rule causing an exception into evaluation process are skipped (assumed as valid)
        if (!(e instanceof FillParamsError)) {
          log.error(e);
        }
      }

      return errors;
    }, [] as ValidationMessage[]);

    // ALFAPS-3367 - ALFAPS-3370:
    // Su un campo di tipo "checkbox-multiple" (o select-multiple) mostrare un messaggio di validazione
    // quando sono presenti più opzioni (> 1) e almeno un'opzione selezionata è disabilitata.
    if (
      ["checkbox-multiple", "select-multiple"].includes(`${field.inputType}`) &&
      field.options &&
      field.options.length > 1 &&
      Array.isArray(field.value) &&
      field.value.length
    ) {
      const selectedAndDisabledOptions = field.options
        ?.filter((o) => o.disabled)
        .filter((o) => field.value.includes(o.value))
        .map((o) => o.value);

      const selectedAndEnabledOptions = field.options
        ?.filter((o) => !o.disabled)
        .filter((o) => field.value.includes(o.value))
        .map((o) => o.value);        

      // TODO: remove console.log
      console.log("----->", {
        fieldId: field.fieldId,
        type: field.inputType,
        options: field.options,
        value: field.value,
        inconsistency: Boolean(selectedAndDisabledOptions?.length),
      });
      if (!selectedAndEnabledOptions?.length && selectedAndDisabledOptions?.length > 1) {
        // Add validation error!
        const message: string = ruleMessage({
          name: "inconsistent-options",
          fieldId: field.fieldId,
          messageKey: "inconsistent-options",
        });

        const validationMessage = toError(message);
        errors.push(validationMessage);
      }
    }

    if (errors.length) {
      return errors;
    }
    return [toSuccess()];
  };

export default validate;
