"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Column = function Column() {
  return null;
};

exports.default = Column;