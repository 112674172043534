import { Icon, Input, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { Permission } from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { useAppDispatch } from "../../shared/store/hooks";
import { updateCustomerReference } from "../../shared/store/item/actions";
import { ExtendedItem } from "../../shared/store/project/types";

interface IProps {
  intl: InjectedIntl;
  item: ExtendedItem;
}

const CustomerReference: React.FC<IProps> = ({ intl, item }) => {
  const id = item.id ?? 0; // Item always has an "id"
  const initialCustomerRef = item.customerReference ?? "";
  const updating = item.updatingCR;
  const [editMode, setEditMode] = useState(false);
  const [customerRef, setCustomerRef] = useState(initialCustomerRef);
  const dispatch = useAppDispatch();
  const updateCustomerRef = useCallback(() => {
    setEditMode(false);
    if (customerRef !== initialCustomerRef) {
      dispatch(updateCustomerReference(id, customerRef));
    }
  }, [dispatch, id, customerRef, initialCustomerRef, setEditMode]);
  const undoOnEsc = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        setCustomerRef(initialCustomerRef);
        setEditMode(false);
      }
    },
    [initialCustomerRef, setCustomerRef, setEditMode]
  );
  useEffect(() => {
    if (!updating) setCustomerRef(initialCustomerRef);
  }, [updating, setCustomerRef, initialCustomerRef]);

  if (item.permission === Permission.READ) return <div>{customerRef}</div>;
  return editMode ? (
    <Input
      key={`p${initialCustomerRef}`}
      placeholder={intl.formatMessage(messages.customerRefPlaceholder)}
      value={customerRef}
      onChange={(e) => setCustomerRef(e.target.value)}
      onBlur={updateCustomerRef}
      onPressEnter={updateCustomerRef}
      autoFocus={true}
      onKeyDown={undoOnEsc}
      maxLength={100}
    />
  ) : (
    <div onClick={() => !updating && setEditMode(true)}>
      {updating ? (
        <Spin size="small" />
      ) : (
        <Icon type="edit" className="edit-icon" />
      )}
      &nbsp;{customerRef}
    </div>
  );
};

export default injectIntl(CustomerReference);
