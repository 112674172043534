'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = {
  // Options.jsx
  items_per_page: '/ página',
  jump_to: 'Saltar',
  jump_to_confirm: 'confirmar',
  page: '',

  // Pagination.jsx
  prev_page: 'Página Anterior',
  next_page: 'Página Seguinte',
  prev_5: 'Recuar 5 Páginas',
  next_5: 'Avançar 5 Páginas',
  prev_3: 'Recuar 3 Páginas',
  next_3: 'Avançar 3 Páginas'
};
module.exports = exports['default'];