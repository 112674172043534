"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Created by Andrey Gayvoronsky on 13/04/16.
 */
var locale = {
  placeholder: 'Выберите время'
};
var _default = locale;
exports["default"] = _default;